import i18n from "@/ts/i18n";

import { getProjectServerStoragePath } from "@utils/formatter";

import { apiRequest } from "@utils/api";
import { srtToVtt } from "@utils/srt";
import { getSubtitleManager } from "@providers/subtitlemanager";
import { getVideoPlayer } from "@providers/videoplayer";

import Channel from "./channel";
import Clip, { ClipType } from "./clip/clip";
import ToastService from "../services/ToastService";
import { QuizData } from "./quiz/quiz";
import { Guide } from "@components/guide/guidewrapper";

export type ProjectLanguageData = {
    primary: boolean;
    narration?: {
        path: string;
    };
    subtitles?: {
        name: string;
        path: string;
        details?: {
            source?: string;
            unique?: boolean;
        };
    }[];
    music?: {
        path: string;
    }
};

export type ProjectLanguages = {
    [language_code: string]: ProjectLanguageData;
};

export type ProjectVideoFile ={
    path: string;
    details: {
        width: number;
        height: number;
        size: number;
    }
};

export type ProjectFiles = {
    videos?: ProjectVideoFile[],
    thumbnails?: {
        path: string;
    }[]
};

export type ProjectLockInfo = {
    expire_at: number;
    user: {
        id: number;
        email: string;
        name: string;
    }
};

export default class Project {
    private TEMP_AI: number = 0;
    public id: number;
    public azon: string = "";
    public name: string = "";
    public frame_rate: number = 24;
    public languages: ProjectLanguages = {};
    public files: ProjectFiles = {};
    public lockInfo: ProjectLockInfo = null;
    public quizzes: QuizData[] = [];
    public guides: Guide[] = [];
    
    public channels: Channel[] = [];
    public selectedClip: Clip;
    public selectedChannel: Channel;
    public hasUnsavedChanges: boolean = false;
    public activeLanguage: string = null;


    constructor() {
        // 
    }

    destructor() {
        this.channels.forEach(channel => {
            channel.delete();
        });
    }

    public exportPayload(): ProjectBackendPayload
    {
        const channels = this.channels.map(channel => channel.exportPayload());
        
        return {
            id:       this.id,
            channels: channels,
            guides: this.guides
        };
    }

    public applyPayload(data: ProjectBackendPayload)
    {
        this.id         = data.id;
        this.azon       = data.azon;
        this.name       = data.name;
        this.frame_rate = data.frame_rate;
        this.languages  = data.languages ?? this.languages;
        this.files      = data.files     ?? this.files;
        this.lockInfo   = data.lockInfo  ?? this.lockInfo;
        this.quizzes    = data.quizzes   ?? this.quizzes;
        this.guides     = data.guides;
        
        data.channels.forEach(channel_values => {
            let channel = new Channel(this);
            channel.applyPayload(channel_values);
            
            this.channels.push(channel);
        });

        this.activeLanguage = this.getPrimaryLanguage();
    }


    public getServerStoragePath(subPath: string = ""): string
    {
        return getProjectServerStoragePath(this.id, subPath);
    }

    public getPrimaryLanguage(): string
    {
        return Object.keys(this.languages).find(v => this.languages[v].primary) ?? null;
    }

    public nextTempID(): number
    {
        return --this.TEMP_AI;
    }


    async toggleLock(): Promise<boolean>
    {
        let res = await apiRequest("POST", `/project/${this.id}/toggle-lock`);
        
        if (! res.success) {
            ToastService.error(res.message || i18n.t("common:something_went_wrong"));
            return false;
        }

        if (res.result.newState === true) {
            this.lockInfo = res.result.lockInfo;
        
        } else {
            this.lockInfo = null;
        }

        return true;
    }

    public isLocked(): boolean
    {
        return !! this.lockInfo;    
    }


    public createChannel(): Channel
    {
        let channel = new Channel(this);
        channel.id  = this.nextTempID();

        this.channels.push(channel);

        return channel;
    }

    public removeChannel(channel: Channel): boolean
    {
        this.channels = this.channels.filter(v => v !== channel);
        
        return true;
    }

    public getChannelByIndex(index: number): Channel
    {
        return this.channels[index];
    }

    public setSelectedClip(clip?: Clip): boolean
    {
        if (this.selectedClip) {
            this.selectedClip.selected = false;
            this.selectedClip.findSubtitleElement().css("pointer-events", "none");
        }

        if (clip) {
            this.selectedClip = clip;
            clip.selected     = true;

            clip.findSubtitleElement().css("pointer-events", "auto");
            this.setSelectedChannel(clip.channel);

        } else {
            this.selectedClip = null;
        }

        return true;
    }

    public setSelectedChannel(channel: Channel): boolean
    {
        if (channel.locked)
            return false;
        
        if (this.selectedChannel) {
            this.selectedChannel.selected = false;
        }

        this.selectedChannel = channel;
        channel.selected     = true;

        return true;
    }

    public getClips(): Clip[]
    {
        let clips: Clip[] = [];

        this.channels.forEach(channel => {
            clips.push(...channel.clips);
        });

        return clips;
    }

    public findChannel(id: number): Channel
    {
        return this.channels.find(v => v.id === id);
    }

    public findClip(id: number): Clip
    {
        return this.getClips().find(v => v.id === id);
    }


    // LANGUAGES

    async loadTranslation(language_code: string): Promise<boolean>
    {
        let res = await apiRequest("GET", `/project/${this.id}/language/langkeys`, { language_code });

        if (res?.success) {
            let [subtitleManager] = getSubtitleManager();
            
            subtitleManager.loadTranslation(res.result);

            this.activeLanguage = language_code;

            return true; 
        }

        return false;
    }

    async loadSrt(language_code: string, translation_language: string): Promise<boolean>
    {
        let res = await apiRequest("GET", `/project/${this.id}/language/srt`, {
            language_code:        language_code,
            translation_language: translation_language
        });

        if (res?.success) {
            let player = getVideoPlayer();

            let vtt  = srtToVtt(res.result);
            let blob = new Blob([ vtt ], { type: "text/vtt" });
            
            player.video.addRemoteTextTrack({
                kind:    "subtitles",
                srclang: translation_language,
                label:   translation_language,
                src:     URL.createObjectURL(blob),
                mode:    "showing"
            });

            return true; 
        }

        return false;
    }
}


export type ChannelBackendPayload = {
    id: number;
    clips: ClipBackendPayload[];
    locked: boolean;
    hidden: boolean;
};

export type ClipBackendPayload = {
    id: number;
    channel_id: number;
    type: ClipType;
} & (
    {
        data: {
            startTime: number;
            duration: number;
            position: any;
            effects: any;
        }
    } & (
        {
            type: "TextClip";
            data: {
                scale: any;
                rotation: any;
                text: any;
                lineHeight: any;
                font: any;
                fontSize: any;
                color: any;
                anchor: any;
                sizeContentFit: any;
                sizeContentFitAnchor: any;
            }
        } | {
            type: "ImageClip";
            data: {
                path: string;
                scale: any;
                rotation: any;
            }
        } | {
            type: "ShapeClip";
            data: {
                scale: any;
                rotation: any;
            }
        } | {
            type: "MarkerClip";
            data: {
                isTimeInterval: any;
                description: any;
                quiz: any;
            }
        }
    )
);

export type ProjectBackendPayload = {
    id: number;
    azon?: string;
    name?: string;
    frame_rate?: number;
    languages?: ProjectLanguages;
    files?: ProjectFiles;
    lockInfo?: ProjectLockInfo,
    channels: ChannelBackendPayload[];
    quizzes?: QuizData[];
    guides?: Guide[];
};
