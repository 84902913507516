import React, { MutableRefObject, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import Menu, { MenuData } from "@components/menu/menu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faCircleCheck, faCompress, faExpand, faGear, faPause, faPlay, faVolumeHigh, faVolumeXmark } from "@fortawesome/free-solid-svg-icons";

import Tooltip from "@components/tippy/tooltip";

import Project from "@models/project";
import VideoPlayer from "@models/videoplayer";
import { resolveLanguageAssetUrls } from "@utils/language";
import { useProjectPageContext } from "./share";

type Props = {
    project: Project;
    player: VideoPlayer;
    disabled?: boolean;
    togglePaused: Function;
    controlRefs: References;
    changeTime: (ms: number) => void
}

type References = {
    progressBarRef: MutableRefObject<HTMLInputElement>,
    progressThumbRef: MutableRefObject<HTMLDivElement>,
    timeDisplayRef: MutableRefObject<HTMLSpanElement>,
    controlRef: MutableRefObject<HTMLDivElement>
}

const AreaControls = ({ project, player, disabled, togglePaused, controlRefs, changeTime }: Props) => {
    const [language, setLanguage] = useState(project.getPrimaryLanguage());
    const [srt, setSRT] = useState("");

    const { t } = useTranslation(["mve", "common"]);

    const { fullscreen, toggleFullscreen, muted, toggleMuted } = useProjectPageContext();


    const availableLanguages = Object.keys(project?.languages);

    let availableSubtitles = [];
    if (project.languages[language]["subtitles"]) {
        availableSubtitles = Object.keys(project.languages[language]?.["subtitles"]);
    }

    const availableResolutions = project.files.videos.map(v => {
        return { x: v.details.width, y: v.details.height, path: v.path };
    });

    const changeSrt = async (translation_language: string) => {
        window.toggleLoadingOverlay(true);

        const isPlaying = !player.video.paused();
        player.setPaused(true);

        if (srt) {
            // @ts-ignore
            let textTracks = player.video.textTracks();

            if (textTracks) {
                textTracks.tracks_.forEach(track => {
                    player.video.removeRemoteTextTrack(track);
                });
            }
        }

        if (translation_language) {
            if (await project.loadSrt(language, translation_language)) {
                setSRT(translation_language);

            } else {
                setSRT("");
                window.toggleLoadingOverlay(false);
                return;
            }

        } else {
            setSRT("");
        }

        player.setCurrentTime(player.currentTime());

        if (isPlaying) {
            player.setPaused(false);
        }
        window.toggleLoadingOverlay(false);
    }

    const changeLanguage = async (code: string) => {
        if (srt) {
            await changeSrt("");
        }

        window.toggleLoadingOverlay(true);

        const isPlaying = !player.video.paused();
        player.setPaused(true);

        if (! await project.loadTranslation(code)) {
            window.toggleLoadingOverlay(false);
            return;
        }

        setLanguage(code);

        let languageAssetUrls = resolveLanguageAssetUrls(project.languages, code);

        if (languageAssetUrls?.narration) {
            player.audioNarration.src = project.getServerStoragePath(languageAssetUrls.narration);
        }
        if (languageAssetUrls?.music) {
            player.audioMusic.src = project.getServerStoragePath(languageAssetUrls.music);
        }

        player.setCurrentTime(player.currentTime());

        if (isPlaying) {
            player.setPaused(false);
        }
        window.toggleLoadingOverlay(false);
    }

    const changeVideoSrc = async (path: string) => {
        await player.loadVideo(
            project.files.videos.find(v => v.path === path)
        );

        window.projectPageForceRerender();
    }

    const SharedMenuData: MenuData[] = [
        {
            title: t("common:language"),
            subMenu:
                availableLanguages.map(lng => {
                    return {
                        title: t(`common:lang_name.${lng}`),
                        onClick: () => {
                            changeLanguage(lng);
                            changeSrt("");
                        },
                        icon: language === lng ? faCircleCheck : faCircle
                    }
                })
        },
        {
            title: t("pages.project.subtitles"),
            subMenu:
                [{
                    title: "Off",
                    icon: srt === "" ? faCircleCheck : faCircle,
                    onClick: () => changeSrt("")
                },
                ...availableSubtitles?.map(subtitleLanguage => {
                    return {
                        title: t(`common:lang_name.${subtitleLanguage}`),
                        icon: srt === subtitleLanguage ? faCircleCheck : faCircle,
                        onClick: () => changeSrt(subtitleLanguage)
                    }
                })]
        },
        {
            title: t("pages.project.resolution"),
            subMenu: availableResolutions.map(res => {
                return {
                    title: `${res.x}x${res.y}`,
                    onClick: () => changeVideoSrc(res.path)
                }
            })
        }
    ];

    return (
        <div className={classNames("area-controls align-items-center gap-2", { "area-disabled": disabled})}
            ref={controlRefs.controlRef}
        >
            <div className="control-row">
                <div className="progressbar d-flex align-items-center-gap-2 w-100">
                    <div className="progress-thumb" ref={controlRefs.progressThumbRef}></div>

                    <input
                        ref={controlRefs.progressBarRef}
                        type="range"
                        id="controls-progress"
                        defaultValue={0}
                        min={0}
                        max={player?.duration().toString()}
                        step={1}
                        onChange={(e) => {
                            const ms = parseInt(e.target.value);
                            player?.setCurrentTime(ms);
                            changeTime(ms);
                        }}
                    />
                </div>
            </div>

            <div className="control-row">
                <div className="controls d-flex align-items-center gap-3">
                    <FontAwesomeIcon
                        icon={player?.video?.paused?.() ? faPlay : faPause}
                        className="control-btn"
                        onClick={_ => togglePaused()}
                    />
                </div>

                <div className="volumebar d-flex align-items-center">
                    <Tooltip text={t("pages.project.mute") + " (M)"}>
                        <FontAwesomeIcon
                            icon={muted ? faVolumeXmark : faVolumeHigh}
                            className="mute control-btn"
                            onClick={() => {
                                toggleMuted();
                            }}
                        />
                    </Tooltip>
                    <div className="volume-range-div">
                        <input
                            type="range"
                            className="volume-range"
                            defaultValue={player?.video?.volume()}
                            min={0}
                            max={1}
                            step={0.01}
                            onChange={e => {
                                const vol = parseFloat(e.target.value);
                                player?.setVolume?.(vol);
                            }}
                        />
                    </div>
                </div>

                <span
                    ref={controlRefs.timeDisplayRef}
                    className="time"
                >
                </span>

                <div className="end-container">
                    <div className="options">
                        <Menu
                            component={<FontAwesomeIcon className="control-btn" icon={faGear} />}
                            data={SharedMenuData}
                            portal={false}
                        />
                    </div>

                    <div></div>

                    <div>
                        <FontAwesomeIcon
                            className="control-btn"
                            onClick={() => { toggleFullscreen() }}
                            icon={fullscreen ? faCompress : faExpand}
                            style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AreaControls;
