import $ from "jquery";
import _, { head } from "lodash";
import videojs from "video.js";
import VideoJSPlayer from "video.js/dist/types/player";
import "video.js/dist/video-js.css";

import SubtitleEditorService from "@services/SubtitleEditorService";
import { getSubtitleManager } from "@providers/subtitlemanager";

import Clip from "./clip/clip";
import SubtitleManager from "./subtitlemanager";
import { ProjectFiles, ProjectVideoFile } from "./project";
import { getProject } from "@providers/project";


export default class VideoPlayer {
    public containerElement: JQuery<HTMLElement>;
    public videoElement: JQuery<HTMLElement>;

    public video: VideoJSPlayer;
    public audioNarration: HTMLAudioElement;
    public audioMusic: HTMLAudioElement;
    public subtitleManager: SubtitleManager;

    private loadedVideoFile?: ProjectVideoFile;

    constructor(containerElement: HTMLElement) {
        const [subtitleManager, setSubtitleManager] = getSubtitleManager();

        // VIDEO
        let video = document.createElement("video");

        this.containerElement = $(containerElement)
            .addClass("video-container");

        this.videoElement = $(video)
            .addClass("video-js")
            .appendTo(this.containerElement);

        // SUBTITLES
        let subtitlesHolderElement = $("<div/>")
            .insertAfter(this.videoElement);

        this.subtitleManager = new SubtitleManager(
            subtitlesHolderElement,
            {
                currentTimeGetter: () => this.currentTime(),
                isPausedGetter:    () => this.video.paused(),
                setPaused:         (state: boolean) => this.setPaused(state)
            }
        );
        setSubtitleManager(this.subtitleManager);

        // AUDIO
        this.audioNarration = new Audio();
        this.audioMusic     = new Audio();

        const onVideoHolderChange = () => {
            this.updateContainer();
        }
        window.addEventListener("resize",           onVideoHolderChange);
        window.addEventListener("fullscreenchange", onVideoHolderChange);

    }

    public create = () => {
        this.video = videojs(this.videoElement[0], {
            autoplay: false,
            controls: false,
            responsive: true,
            fluid: true
        });

        this.setVolume(0.2);

        return this.video;
    }

    public loadVideo = async (videoFile: ProjectVideoFile) => {
        const project = getProject();

        const path = project.getServerStoragePath(videoFile.path);

        this.loadedVideoFile = videoFile;
        await this.setSource(path);
    };

    private setSource = (src: string): Promise<VideoJSPlayer> => new Promise(resolve => {
        const this_ = this;

        this_.video.src({ src });

        this_.video.one("loadedmetadata", function() {
            resolve(this_.video);
        });
    });

    public setPaused = (state: boolean = true): boolean => {
        SubtitleEditorService.setTarget(null);

        this.subtitleManager.setPaused(state);

        if (state) {
            this.video.pause();

            if (this.audioNarration?.src) {
                this.audioNarration.pause();
            }
            if (this.audioMusic?.src) {
                this.audioMusic.pause();
            }

        } else {
            this.video.play();

            if (this.audioNarration?.src) {
                this.audioNarration.play();
            }
            if (this.audioMusic?.src) {
                this.audioMusic.play();
            }
        }

        return state;
    }

    public togglePaused = () => {
        return this.setPaused(! this.video.paused());
    }

    public setVolume = (volume: number) => {
        this.video.volume(volume);
        this.audioNarration.volume = volume;
        this.audioMusic.volume     = volume;
    }

    public setMuted = (state: boolean = false): boolean => {
        this.video.muted(state);

        this.audioNarration.muted = state;
        this.audioMusic.muted     = state;

        return state;
    }

    public toggleMute = (): boolean => {
        let newState = ! this.video.muted();

        this.video.muted(newState);
        this.audioNarration.muted = newState;
        this.audioMusic.muted     = newState;

        return newState;
    }

    public duration = () => {
        return Number( (this.video.duration() * 1000).toFixed(3) );
    }

    public currentTime = () => {
        return Number( (this.video.currentTime() * 1000).toFixed(3) );
    }

    public setCurrentTime = (ms: number, isOffset: boolean = false) => {
        let newTime = isOffset ? this.currentTime() + ms : ms;

        this.video.currentTime(newTime / 1000);

        this.subtitleManager.updateSubtitles();
        this.subtitleManager.updateEffects();

        if (this.audioNarration?.src) {
            this.audioNarration.currentTime = newTime / 1000;
        }
        if (this.audioMusic?.src) {
            this.audioMusic.currentTime = newTime / 1000;
        }
    }

    public getTimeString = (_time?: number, round: boolean = false) => {
        let time = _time ?? this.currentTime();
        let m    = Math.floor(time / 60000);
        let s    = Math.floor((time - m*60000) / 1000);
        let ms   = Math.round(time - (s*1000) - (m*60000));
        if (round){
            return `${m < 10 ? "0"+m : m}:${s < 10 ? "0"+s : s}`;
        }
        return `${m < 10 ? "0"+m : m}:${s < 10 ? "0"+s : s}.${"0".repeat(Math.max(0, 3 - String(ms).length)) + ms}`;
    }

    public timeStringToMS = (str: string) => {
        let args = (/(?<m>\d+):(?<s>\d+)\.(?<ms>\d+)/g).exec(str);

        if (! args)
            return 0;

        return (parseInt(args.groups.m) * 60000) + (parseInt(args.groups.s) * 1000) + parseInt(args.groups.ms);
    }

    public fullscreen = () => {
        SubtitleEditorService.setTarget(null);

        this.containerElement[0].requestFullscreen();
        return true;
    }

    public toggleFullscreen = () => {
        if (document.fullscreenElement) {
            document.exitFullscreen();
            return false;

        } else {
            this.containerElement[0].requestFullscreen();
            return true;
        }
    }

    public getDimensions() {
        return [
            this.videoElement.width(),
            this.videoElement.height()
        ];
    }

    public calcRelativeDimensions = (x: number, y: number) => {
        return [
            x  / this.videoElement.width()  * 100,
            y  / this.videoElement.height() * 100
        ];
    }

    public getVideoScaleRatio = () => {
        return this.videoElement.width() / this.videoElement.height();
    }

    public updateContainer = () => {
        let aspectRatio = this.loadedVideoFile.details.width / this.loadedVideoFile.details.height;
        let parent = this.containerElement.parent();
        let maxWidth = 0;

        if (parent.height() * aspectRatio < parent.width()){
            maxWidth = parent.height() * aspectRatio;

        } else {
            maxWidth = parent.width();
        }

        this.containerElement.css({
            maxWidth: `${maxWidth}px`
        });

        this.subtitleManager.setSize(maxWidth, maxWidth / aspectRatio);

        this.subtitleManager.updateSubtitles();
        this.subtitleManager.updateEffects();
    }


    // TIMELINE

    public getTimelineSnapPointsMS = (includeCaret?: boolean, filteredClips?: Clip[], snapTolerance: number = 750): number[] => {
        let list: number[] = [];

        if (includeCaret) {
            list.push(this.currentTime());
        }

        _.difference(this.subtitleManager.clips, filteredClips).forEach(clip => {
            list.push(clip.startTime, clip.startTime + clip.duration);
        });

        // remove duplicates (values with less than {snapTolerance}ms difference)
        let uniqueList: number[] = [];

        _.each(list, (a, k1) => {
            let foundSimiliar = _.findIndex(uniqueList, (b, k2) => Math.abs(a - b) < snapTolerance) >= 0;

            if (! foundSimiliar) {
                uniqueList.push(a);
            }
        });

        return uniqueList;
    }


}
